import AppDispatcher from 'dispatcher/AppDispatcher'
import QualAPI from 'utils/QualAPI'
import UpgradeConstants from 'constants/UpgradeConstants'
import SubscriptionAPI from 'apis/SubscriptionAPI'

var UpgradeActions = {
  /***************************\
  *
  * Fetch Upgrades
  *
  \***************************/
  fetchUpgrades: function()
  {
    AppDispatcher.handleAction({
      actionType: UpgradeConstants.FETCH_UPGRADES,
      data:{}
    })

    return QualAPI.fetchUpgrades().then((response)=>{
      AppDispatcher.handleAction({
        actionType: UpgradeConstants.FETCH_UPGRADES_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: UpgradeConstants.FETCH_UPGRADES_ERROR,
        data: {},
        error: error
      })
    });
  },
  /***************************\
  *
  * Fetch Upgrades
  *
  \***************************/
  upgradeSubscription: function(plan_id)
  {
    AppDispatcher.handleAction({
      actionType: UpgradeConstants.CREATE_UPGRADE,
      data:{}
    })

    return QualAPI.createUpgrade(plan_id).then((response)=>{
      AppDispatcher.handleAction({
        actionType: UpgradeConstants.CREATE_UPGRADE_RESULT,
        data: response.data
      })
    }).catch((error)=>{
      AppDispatcher.handleAction({
        actionType: UpgradeConstants.CREATE_UPGRADE_ERROR,
        data: {},
        error: error
      })
    });
  },
  /***************************\
   * 
   * End Trial
   * 
  \**************************/
  endTrial: function(subscription_id) {
    return SubscriptionAPI.endTrial(subscription_id).then((response)=>{
      AppDispatcher.handleAction({
        actionType: UpgradeConstants.CREATE_UPGRADE_RESULT,
        data: response.data
      })
      return response;
    })
  }
}

export default UpgradeActions
