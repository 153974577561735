// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import BillingModalMainRenew from './BillingModalMainRenew';

function BillingModalMainBillingActive ({ subscription = {} }) {
  return (<BillingModalMainRenew
      subscription={subscription}
    >
      <p>
        Your subscription will automatically renew on {subscription.current_period_end} and you'll be charged ${subscription.total}.
      </p>
  </BillingModalMainRenew>)
}

export default BillingModalMainBillingActive
