import AuthStore from 'stores/AuthStore'
import axios from 'axios';
import API from 'apis/API'

var _ = require('underscore');

var QualAPI = {
  /***********************\
  *
  * Auth Actions
  *
  \************************/
  signIn: function(email, password)
  {
    return axios.post(`/api/auth/sign_in`, {
      email: email,
      password: password
    })
  },

  signUp: function(name, email, password)
  {
    const host = window && window.location && window.location.host ? window.location.host : "app.delvetool.com";
    const confirmation_url = `https://${host}`
    var date = new Date();
    return axios.post(`/api/auth`, {
      name: name,
      email: email,
      password: password,
      accepted_terms_at:date.toString(),
      confirm_success_url: confirmation_url,
    })
  },

  signOut: function()
  {
    return axios.delete(`/api/auth/sign_out`, AuthStore.getHeader());
  },

  changePassword: function(currentPassword, newPassword, verificationPassword)
  {
    return API.put(`/api/auth/password`, {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: verificationPassword
    });
  },

  forgotPassword: function(email)
  {
    return axios.post(`/api/auth/password`, {
      email:email,
      redirect_url: 'app.delvetool.com/reset' // Not actually needed, but needed for this to work
    })
  },

  resetPassword: function(token, newPassword, verificationPassword)
  {
    return axios.put(`/api/auth/password`, {
      reset_password_token: token,
      password: newPassword,
      password_confirmation: verificationPassword
    })
  },

  acceptInvite: function(token, newPassword, verificationPassword, name)
  {
    var date = new Date();
    return axios.put(`/api/auth/invitation`, {
      invitation_token: token,
      password: newPassword,
      password_confirmation: verificationPassword,
      name: name,
      accepted_terms_at:date.toString(),
    })
  },

  getProjects: function() {
    return API.get(`/api/projects/`);
  },

  // load single projects
  getProject: function(id) {
    return API.get(`/api/projects/${id}`);
  },

  newProject: function(projectName, projectDescription) {
    return API.post('/api/projects', {
      name: projectName,
      description: projectDescription
    });
  },

  duplicateProject: function(projectID) {
    return API.post(`/api/projects/${projectID}/duplicates`);
  },

  updateProjectName: function(projectID, name)
  {
    return API.put(`/api/projects/${projectID}`, {
      name: name
    });
  },

  // Load mock product data from localStorage into ProductStore via Action
  getCodes: function(projectId) {
    return API.get(`/api/projects/${projectId}/codes`);
  },

  addCode: function(data) {
    // WARNING: this code is only for testing, make sure to comment it out
    /*
    return new Promise((resolve, reject)=>{
      setTimeout(function () {
        return API.post(`/api/projects/${data.projectId}/codes`,{
          name: data.name,
          clientID: data.clientID
        }).then((response)=>resolve(response));
      }, 10000 );
    })
    */

    return API.post(`/api/projects/${data.projectId}/codes`,{
      name: data.name,
      clientID: data.clientID
    });
  },

  getCode: function(codeID) {
    return API.get(`/api/codes/${codeID}`)
  },

  getCodeExcerpts: function(codeID, next) {
    const offset = next ? `&offset=${next}` : '';
    return API.get(`/api/codes/${codeID}/excerpts?limit=100${offset}`)
  },

  deleteCode: function(codeID) {
    return API.delete(`/api/codes/${codeID}`)
  },

  renameCode: function(codeID, name) {
    return API.put(`/api/codes/${codeID}`, {
      name: name
    })
  },

  updateCodeSynthesis: function(codeID, synthesis) {
    return API.put(`/api/codes/${codeID}`, {
      synthesis: synthesis
    })
  },

  nestCode: function(codeID, parentCodeID, position) {
    if ( parentCodeID || position ) {
      return API.post(`/api/codes/${codeID}/nests`, {
        code: {
          parent_id: parentCodeID || null,
          position
        }
      });
    }
    else {
      return API.delete(`/api/codes/${codeID}/nests/${codeID}`);
    }
  },

  getTranscript: function(transcriptId) {
      return API.get( `/api/transcripts/${transcriptId}` );
  },

  deleteCodeFromExcerpt: function(session_id, code_id, excerpt_id, user_ids)
  {
    user_ids = !!user_ids && user_ids.length > 0 ? user_ids : null;
    
    // if users exist add ?user_ids=1,2,3 to the url
    const url = [
      `/api/codes/${code_id}/excerpts/${excerpt_id}`,
      user_ids && `user_ids=${user_ids.join(',')}`
    ].filter((part)=>part).join('?');
    
    return API.delete(url, {session_id:session_id}).then((response)=>{
      
      // include users if they are passed in
      return {
        excerpt:response.data,
        code_id:code_id,
        ...(user_ids && {user_ids})
      }
    });
  },

  createTranscript: function(project_id, name, body)
  {
    return API.post(`/api/projects/${project_id}/transcripts/`, {
      name: name,
      body: body,
    });
  },

  updateTranscript: function(transcript_id, payload)
  {
    return API.put(`/api/transcripts/${transcript_id}/`, {transcript:payload});
  },

  createTranscriptVersion: function(transcript_id, payload)
  {
    return API.post(`/api/transcripts/${transcript_id}/versions`, {transcript:payload});
  },

  deleteTranscript: function(transcript_id)
  {
    return API.delete(`/api/transcripts/${transcript_id}`)
  },

  /*********************************************\
  *
  * Descriptor
  *
  \*********************************************/
  fetchDescriptors: function(project_id)
  {
    return API.get(`/api/projects/${project_id}/descriptors`);
  },

  createDescriptor: function(projectID, name, allChoices)
  {
    return API.post(`/api/projects/${projectID}/multi_choice_descriptors`, {
      multi_choice_descriptor: {
        name: name,
        multi_choices: allChoices
      }
    });
  },

  deleteDescriptor: function(id, project_id)
  {
    return API.delete(`/api/multi_choice_descriptors/${id}`, {}).then((result)=>{
        return {
          data: {
            id: id,
            project_id: project_id
          }
        }
    });
  },

  updateDescriptor: function(id, name, allChoices)
  {
    var multiChoiceDescriptor = {
      multi_choices: allChoices
    }

    if ( name ) Object.assign(multiChoiceDescriptor, {name: name})

    const payload = {
      multi_choice_descriptor: multiChoiceDescriptor
    }

    return API.put(`/api/multi_choice_descriptors/${id}`,payload);
  },

  createMultiChoice: function(id, name)
  {
    return API.post(`/api/multi_choice_descriptors/${id}/multi_choices`, {
      choice: name
    });
  },

  /*********************************************\
  *
  * Selections
  *
  \*********************************************/
  fetchSelections: function(transcript_id)
  {
    return API.get(`/api/transcripts/${transcript_id}/selections`)
  },

  fetchProjectSelections: function(project_id)
  {
    return API.get(`/api/projects/${project_id}/selections`)
  },

  createMultiChoiceSelection: function(transcriptID, choiceID)
  {
    return API.post(`/api/transcripts/${transcriptID}/multi_choice_selections`, {
      multi_choice_selection: {
        multi_choice_id: choiceID
      }
    });
  },

  /*********************************************\
  *
  * User fetch
  *
  \*********************************************/
  fetchUser: function()
  {
    return API.get(`/api/users`)
  },

  acceptTermsOfService: function()
  {
    return API.put(`/api/users/me`, {
      accepted_terms: true
    })
  },

  /*********************************************\
  *
  * Collaborators
  *
  \*********************************************/
  getCollabList: function(project_id)
  {
    return API.get(`/api/projects/${project_id}/project_shares`)
  },

  addCollborator: function(project_id, email, role)
  {
    return API.post(`/api/projects/${project_id}/project_shares`, {
      email,
      role: role || 'edit'
    })
  },

  removeCollaborator: function(project_id, collaborator_id)
  {
    return API.delete(`/api/projects/${project_id}/project_shares/${collaborator_id}`)
  },

  updateCollaborator: function(project_id, collaborator_id, role)
  {
    return API.put(`/api/projects/${project_id}/project_shares/${collaborator_id}`, {
      role
    })
  },

  /*********************************************\
  *
  * Cashier
  *
  \*********************************************/
  fetchSubscriptions: function()
  {
    return API.get(`/api/subscriptions`);
  },

  // TODO: deprecate this
  subscribe: function(plan_id, coupon_id)
  {
    return API.post(`/api/plans/${plan_id}/subscriptions`, {
      ...(coupon_id && {coupon_id})
    });
  },

  cancelSubscription: function(subscription_id)
  {
    return API.put(`/api/subscriptions/${subscription_id}`, {cancel: true});
  },

  renewSubscription: function(subscription_id)
  {
    return API.put(`/api/subscriptions/${subscription_id}`, {cancel: false});
  },

  fetchPlans: function()
  {
    return API.get(`/api/plans`, {});
  },

  fetchSeats: function(subscriptionID)
  {
    return API.get(`/api/subscriptions/${subscriptionID}/seats`)
  },

  addSeat: function(subscriptionID, email)
  {
    return API.post(`/api/subscriptions/${subscriptionID}/seats`, {
      seat:{
        email: email
      }
    })
  },

  removeSeat: function(subscriptionID, seatID)
  {
    return API.delete(`/api/subscriptions/${subscriptionID}/seats/${seatID}`)
  },

  /*********************************************\
  *
  * Feedback
  *
  \*********************************************/
  sendFeedback: function(feedback)
  {
    feedback = feedback || {};
    return API.post(`/api/feedback`, {
      feedback: {
        type: 'pause',
        message: feedback.message,
        choice: feedback.choice
      }
    })
  },

  /********************************************\
  *
  * Merge Codes
  *
  \********************************************/
  mergeCode: (mergeCodeId, mergeIntoCodeId) => {
    return API.post(`/api/codes/${mergeIntoCodeId}/merge_codes`, {
      code:{
        id: mergeCodeId
      }
    })
  },

  /********************************************\
  *
  * UPGRADES
  *
  \********************************************/
  fetchUpgrades: () => {
    return API.get(`/api/upgrades`);
  },

  createUpgrade: (plan_id) => {
    return API.post(`/api/upgrades`, {
      plan_id: plan_id
    })
  },
};

export default QualAPI
