// app/javascript/projects/components/ProjectsDisplay.jsx
import React, {useCallback} from 'react';
import BillingModalMainRenew from './BillingModalMainRenew';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';

function BillingModalMainBillingTrialing ({ subscription = {}, history }) {
  const upgradeSubscription = useCallback(() => {
    history.push('/users/upgrade');
  }, [history]);

  const upgrade = <div>
    <p>
      Upgrade to a paid plan for full access to Delve's AI features.
    </p>
    
    <div style={{ margin: '20px 20px 29px 0px' }}>
      <Button bsStyle="white" onClick={upgradeSubscription}>
        Upgrade to Paid Subscription
      </Button>
    </div>
  </div>

  return (<BillingModalMainRenew
      subscription={subscription}
      postDiv={upgrade}
    >
      <p>
        Your free trial ends on {subscription.current_period_end}. After that, you'll be charged ${subscription.total} per {subscription.interval}.
      </p>
  </BillingModalMainRenew>)
}

export default withRouter(BillingModalMainBillingTrialing);
