// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import UserDisplaySectionContainer from './UserDisplaySectionContainer'; 

function getSeparatelyInvoicedCopy(userIsOwner, subscriptionCanceled) {
  if ( !subscriptionCanceled ) {
    return null;
  }
  else if ( !!userIsOwner )  {
    return <div style={{marginBottom: '10px'}}>
      <p>
        Your Delve subscription has expired. Please contact the Delve team to renew it.
      </p>
      <Button bsStyle='blue' id="delve_help_link" href="mailto:team@delvetool.com">
        Contact Us
      </Button>
    </div>
  } else {
    return <p>
      Your Delve subscription has expired. Contact your team owner to renew it, or start your own subscription.
    </p>
  }
}

function subscriptionWillCancelCopy(subscription) {
  return (<p>
      Your plan will end on {subscription.current_period_end}.
  </p>)
}

function userIsNotOwnerCopy(subscriptionIsCanceled, subscriptionWillCancel, subscriptionIsPastDue, subscription) {
  if ( subscriptionIsCanceled ) {
    return <p>
      Your subscription has expired. Please contact your team owner about renewing your subscription
      or start a new subscription.
    </p>
  } else if ( subscriptionIsPastDue ) {
    return <p>
      Your subscription is past due. Please ask your team owner to renew the subscription.
    </p>
  }
  else if ( subscriptionWillCancel ) {
    return subscriptionWillCancelCopy(subscription);
  }
  else {
    return (<div>
        <p>Your plan will automatically renew on {subscription.current_period_end}.</p>
        <p>Contact your team owner if you have questions about your plan.</p>
    </div>)
  }
}

function userIsOwnerCopy(subscriptionIsCanceled, subscriptionWillCancel, subscriptionIsPastDue, subscription) {
  if ( subscriptionIsCanceled ) {
    return <p>Your subscription has expired. Please renew your subscription to access Delve.</p>
  } else if ( subscriptionIsPastDue ) {
    return <p>Your subscription is past due. Please update your payment information to continue using Delve.</p>
  }
  else if ( subscriptionWillCancel ) {
    return subscriptionWillCancelCopy(subscription);
  } else if ( subscription.status == 'trialing' ) {
    return <p>Your free trial ends on {subscription.current_period_end}. After that, you'll be charged ${subscription.total} per {subscription.interval}.</p>
  } else { // active subscription
    return <p>Your plan will automatically renew on {subscription.current_period_end} and you'll be charged ${subscription.total}.</p>
  }
}

function BillingComponent(props) {
  const subscriptionState = props.subscriptionState || {};
  const subscription = subscriptionState.subscription || {};
  const loaded = !!subscriptionState.loaded;
  const noSubscription = (!subscriptionState.subscription && loaded);
  const subscriptionCanceled = subscription.status == 'canceled';
  const subscriptionIsPastDue = subscription.status == 'past_due';
  const subscriptionWillCancel = subscription.cancel_at_period_end;
  const userIsOwner = subscription.isOwner;
  const freeAccount = props.freeAccount;
  const {isSeparatelyInvoiced} = subscription;

  if ( freeAccount )
    return (null);

  let subscriptionCopy;
  if ( !loaded )
    subscriptionCopy = null;
  else if ( noSubscription )
    subscriptionCopy = null;
  else if ( isSeparatelyInvoiced ) {
    if ( !subscriptionCanceled ) // if active, don't show anything
      return null;
    subscriptionCopy = getSeparatelyInvoicedCopy(userIsOwner, subscriptionCanceled);
  }
  else if ( !userIsOwner ) {
    subscriptionCopy = userIsNotOwnerCopy(subscriptionCanceled, subscriptionWillCancel, subscriptionIsPastDue, subscription);
  } else {
    subscriptionCopy = userIsOwnerCopy(subscriptionCanceled, subscriptionWillCancel, subscriptionIsPastDue, subscription);
  }

  return (
    <UserDisplaySectionContainer header="Subscription and Billing">
      {subscriptionCopy}

      {userIsOwner && !isSeparatelyInvoiced &&
        <Button bsStyle='blue' onClick={props.onShowBillingModal}>
          <span>Manage Subscription and Billing</span>
        </Button>
      }
      {((!userIsOwner && subscriptionCanceled) || (noSubscription)) &&
        <Link style={{ textDecoration: 'none' }} to={`/subscribe`}>
          <Button bsStyle='blue'>Start New Subscription</Button>
        </Link>
      }
    </UserDisplaySectionContainer>
  );
}

export default BillingComponent;
