// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Button, Alert} from 'react-bootstrap'
const queryString = require('query-string');
import FieldGroup from './FieldGroup'
import { withRouter } from 'react-router';
import TermsBlurb from './TermsBlurb'
import DelveAccountBody from './DelveAccountBody'
import DimensionsConstants from 'constants/DimensionsConstants'

class TokenDisplay extends React.Component {
  constructor () {
    super();
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onConfirmPasswordChange = this.onConfirmPasswordChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.state = {
      password: "",
      confirmPassword: "",
      name: "",
    }
  }

  _getToken()
  {
    const parsed = queryString.parse(this.props.location.search);
    return parsed.token;
  }

  onSubmit(e)
  {
    const token = this._getToken();
    this.props.tokenAction(token, this.state.password, this.state.confirmPassword, this.state.name);
    e.preventDefault()
  }

  onPasswordChange (e) {
    this.setState({
      password: e.target.value
    })
  }

  onConfirmPasswordChange (e) {
    this.setState({
      confirmPassword: e.target.value
    })
  }

  onNameChange (e) {
    this.setState({
      name: e.target.value
    })
  }

  render () {
    return (
      <DelveAccountBody error={this.props.error} subHeader={this.props.header} login>
        <div style={{width:DimensionsConstants.ACCOUNT_WIDTH, margin: "0 auto"}}>
          <form onSubmit={this.onSubmit}>

            {this.props.name &&
              <FieldGroup
                id="formControlsName"
                type="name"
                label="Name"
                value={this.state.name}
                onChange={this.onNameChange}
              />
            }

            <FieldGroup id="formControlsPassword"
                        label="Password"
                        type="password"
                        onChange={this.onPasswordChange}
                        value={this.state.password}/>

            <FieldGroup id="formControlsVerifyPassword"
                        label="Verify Password"
                        type="password"
                        onChange={this.onConfirmPasswordChange}
                        value={this.state.confirmPassword}/>

            {this.props.terms &&
              <TermsBlurb/>
            }

            <div style={{width: "100%", marginTop:"30px"}}>
              <div style={{width: DimensionsConstants.ACCOUNT_WIDTH, margin: "0 auto"}}>
                <Button bsStyle="blue" type="submit" style={{width:"100%"}}>{this.props.buttonText}</Button>
              </div>
            </div>
          </form>

        </div>
      </DelveAccountBody>
    );
  }
}

export default withRouter(TokenDisplay);
