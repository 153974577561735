// app/javascript/projects/components/ProjectsDisplay.jsx

import React, {useCallback, useState} from 'react';
import AccountSingleButton from 'projects/components/AccountSingleButton'
import UpgradeActions from 'actions/UpgradeActions';

function BillingModalEndTrial({subscription, freeAccount, onBack}) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const upgradeSubscription = useCallback(() => {
    setLoading(true);
    UpgradeActions.endTrial(subscription.id).then(() => {
      setLoading(false);
      onBack()
    }).catch((error)=>{
      setLoading(false);
      setErrorMessage(error?.response?.data?.error || 'An error occurred, could not upgrade subscription');
    })
  }, [subscription.id]);

  // if subscription is not trialing redirect to /users
  if (!!subscription.status && (
    subscription.status !== 'trialing'
    || subscription.isOwner === false
    || freeAccount
    || subscription.isSeparatelyInvoiced
  )) {
    onBack();
    return null;
  }


  //const subscription = this.props.subscription ? this.props.subscription : {};
  return (
    <div>
      <AccountSingleButton
        error={errorMessage && {
          error: true,
          message: errorMessage
        }}
        loading={loading}
        header="Upgrade to paid subscription"
        button="Upgrade to Paid Subscription"
        disclaimer={`By upgrading, you agree to be immediately charged and to automatic ${subscription.interval}ly billing until you choose to cancel.`}
        onClick={upgradeSubscription}
        body={
          <p>
            Unlock full access to all Delve features by upgrading to a paid subscription for ${subscription.total}.
            Your account will be charged immediately, and you’ll enjoy uninterrupted access to all features.
          </p>
        }
      />
    </div>
  );
}

export default BillingModalEndTrial;
