// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import BillingModalMainBillingCanceled from './BillingModalMainBillingCanceled';
import BillingModalMainBillingTrialing from './BillingModalMainBillingTrialing';
import BillingModalMainBillingActive from './BillingModalMainActive';


function BillingModalMainBilling({ subscription = {} }) {
  // if the subscription is canceled
  const status = subscription?.status;
  const canceled = subscription?.cancel_at_period_end;

  if ( !status ) {
    return null;
  }
  else if ( status == 'canceled' || canceled ) {
    return <BillingModalMainBillingCanceled subscription={subscription} />
  } else if ( status == 'trialing' ) {
    return <BillingModalMainBillingTrialing subscription={subscription} />
  } else {
    return <BillingModalMainBillingActive subscription={subscription} />
  }
}

export default BillingModalMainBilling;
