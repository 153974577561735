import React from 'react';
import CashierActions from 'actions/CashierActions';
import { UNCANCEL_SUBSCRIPTION } from 'constants/AccountPageConstants';
import BillingModalMainWrapper from './BillingModalMainWrapper';

const SecondParagraph = ({ subscription }) => {
  const status = subscription.status || '';
  if (status !== 'canceled') {
    return (
      <p>
        Your subscription will end on {subscription.current_period_end}. Renew your plan for ${subscription.total}/{subscription.interval}.
      </p>
    );
  } else {
    return (
      <p>
        Renew your plan for ${subscription.total}/{subscription.interval}.
      </p>
    );
  }
}

const BillingModalMainBillingCanceled = ({ subscription = {} }) => {
  const renewSubscription = () => {
    CashierActions.gotoTeamModal(UNCANCEL_SUBSCRIPTION);
  };

  return <BillingModalMainWrapper
    subscription={subscription}
    buttonText="Renew Subscription"
    onButtonClick={renewSubscription}
  >
    <SecondParagraph subscription={subscription} />
  </BillingModalMainWrapper>
};

export default BillingModalMainBillingCanceled;
