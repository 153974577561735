import React from 'react';
import { Button } from 'react-bootstrap';

// Base Component
const BillingModalMainWrapper = ({ subscription = {}, children, buttonText, onButtonClick, postDiv }) => {
  const amount = subscription.quantity || 0;
  const memberString = amount === 1 ? `${amount} member` : `${amount} members`;

  return (
    <div>
      <h4>Subscription and Billing</h4>
      <div style={{ maxWidth: '329px' }}>
        <p>
          Your subscription is ${subscription.amount}/{subscription.interval} per team member and you have {memberString}.
        </p>
        {children}
      </div>
      <div style={{ margin: '20px 20px 29px 0px' }}>
        <Button bsStyle="white" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </div>
      {postDiv}
    </div>
  );
};

export default BillingModalMainWrapper;