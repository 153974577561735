// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import {Alert} from 'react-bootstrap'
import QualCodeActions from 'actions/QualCodeActions'
import CashierActions from 'actions/CashierActions'
import UserStore from 'stores/UserStore'
import AuthStore from 'stores/AuthStore'
import CashierStore from 'stores/CashierStore'
import PasswordModal from './PasswordModal'
import UpdateEmailModal from './UpdateEmailModal'
import BillingModal from './BillingModal'
import TeamModal from './TeamModal'
import AccountPageConstants from 'constants/AccountPageConstants'
import { Scrollbars } from 'react-custom-scrollbars';
import ProfileComponent from './ProfileComponent'
import BillingComponent from './BillingComponent'
import TeamComponent from './TeamComponent'
import DataComponent from './DataComponent'

const LEFT_MARGIN = "30px"

class UserDisplay extends React.Component {
  constructor () {
    super();
    this.state = {
      user: UserStore.getUser(),
      subscriptions: null,
      passwordState: AuthStore.getPasswordState(),
      showExpiredModal: true,
      ...CashierStore.getSubscriptions(),
      ...CashierStore.getTeamModal(),
      ...CashierStore.getAddSeatState(),
      ...CashierStore.getRemoveSeatState(),
      ...CashierStore.getCancelSubscriptionState(),
      ...CashierStore.getUncancelSubscriptionState(),
      upgradeInfo: CashierStore.getUpgradeInfo(),
      showEmailModal: false,
    };

    this._onChange = this._onChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.showEmailModal = this.showEmailModal.bind(this);
    this.hideEmailModal = this.hideEmailModal.bind(this);
    this.logout = this.logout.bind(this);

    this.onShowBillingModal = this.onShowBillingModal.bind(this);
    this.onHideBillingModal = this.onHideBillingModal.bind(this);

    this.onShowTeamModal = this.onShowTeamModal.bind(this);
    this.onHideTeamModal = this.onHideTeamModal.bind(this);

    this.onExpireModalHide = this.onExpireModalHide.bind(this);
    this.onExpireButtonClick = this.onExpireButtonClick.bind(this);
  }

  onExpireModalHide(e)
  {
      this.setState({showExpiredModal: false})
  }

  onShowTeamModal(e)
  {
    CashierActions.gotoTeamModal(AccountPageConstants.MAIN_PAGE)
  }

  onHideTeamModal(e)
  {
    CashierActions.gotoTeamModal(null)
  }

  onShowBillingModal(e)
  {
    CashierActions.gotoTeamModal(AccountPageConstants.PLAN_AND_BILLING);
  }

  onHideBillingModal(e)
  {
    CashierActions.gotoTeamModal(null);
  }

  onExpireButtonClick(e)
  {
    this.setState({
      showExpiredModal: false,
    })
    CashierActions.gotoTeamModal(AccountPageConstants.PLAN_AND_BILLING);
  }

  logout() {
    QualCodeActions.signOut();
  }

  changePassword(e)
  {
    QualCodeActions.showPasswordModal(true);
  }

  showEmailModal(e) {
    this.setState({
      showEmailModal: true,
    })
  }

  hideEmailModal(e) {
    this.setState({
      showEmailModal: false,
    })
  }

  _onChange()
  {
    this.setState({
      user: UserStore.getUser(),
      passwordState: AuthStore.getPasswordState(),
      ...CashierStore.getSubscriptions(),
      ...CashierStore.getTeamModal(),
      ...CashierStore.getAddSeatState(),
      ...CashierStore.getRemoveSeatState(),
      ...CashierStore.getCancelSubscriptionState(),
      ...CashierStore.getUncancelSubscriptionState(),
      upgradeInfo: CashierStore.getUpgradeInfo(),
    })
  }

  componentDidMount () {
    UserStore.addChangeListener(this._onChange);
    AuthStore.addChangeListener(this._onChange);
    CashierStore.addChangeListener(this._onChange);
  }

  componentWillUnmount () {
    // NOTE: the way the code is currently working, the call back is being called
    // but this is then unmounted. Becauser it's unmounted it unsubscribes.
    // however I guess the callbacks are still in the queue
    // Not sure if there is a better way to design this...
    // App.jsx is causing this to hapen I believe...
    UserStore.removeChangeListener(this._onChange);
    AuthStore.removeChangeListener(this._onChange);
    CashierStore.removeChangeListener(this._onChange);
  }

  render () {
    const subscription = this.state.subscriptionState.subscription ? this.state.subscriptionState.subscription : {};
    const freeAccount = subscription.total === 0;

    return (
      <Scrollbars autoHide>
        <div style={{padding:"5px", height:"100%"}}>
          <PasswordModal passwordState={this.state.passwordState}/>
          <UpdateEmailModal
            show={this.state.showEmailModal}
            onHide={this.hideEmailModal}
          />
          <BillingModal show={this.state.modalState.showBillingModal}
                        page={this.state.modalState.page}
                        onHide={this.onHideBillingModal}
                        subscription={subscription}
                        user={this.state.user}
                        cancelSubscriptionInfo={this.state.cancelSubscription}
                        uncancelSubscriptionInfo={this.state.uncancelSubscription}
                        upgradeInfo={this.state.upgradeInfo}
                        freeAccount={freeAccount}
          />
          <TeamModal  onHide={this.onHideTeamModal}
                      subscription={subscription}
                      page={this.state.modalState.page}
                      show={this.state.modalState.showTeamModal}
                      modalState={this.state.modalState}
                      addSeatInfo={this.state.addSeat}
                      removeSeatInfo={this.state.removeSeat}
          />
          <div style={{margin:`10px ${LEFT_MARGIN}`}}>
            {(this.state.passwordState.message && this.state.passwordState.success) &&
              <Alert bsStyle="success">
                <p>
                  {this.state.passwordState.message}
                </p>
              </Alert>
            }
          </div>

            <ProfileComponent
              user={this.state.user}
              changePassword={this.changePassword}
              changeEmail={this.showEmailModal}
              freeAccount={freeAccount}
            />
          <div>
            <BillingComponent
              subscriptionState={this.state.subscriptionState}
              onShowBillingModal={this.onShowBillingModal}
              freeAccount={freeAccount}
            />
            <TeamComponent
              subscription={this.state.subscriptionState.subscription}
              onShowTeamModal={this.onShowTeamModal}
              freeAccount={freeAccount}
            />
            <DataComponent/>
          </div>
        </div>
      </Scrollbars>
    );
  }
}

export default UserDisplay;
