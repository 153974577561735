// app/javascript/projects/components/ProjectsDisplay.jsx
import React from 'react';
import CashierActions from 'actions/CashierActions'
import BillingModalMainWrapper from './BillingModalMainWrapper';

import {
  PAUSE_FEEDBACK,
} from 'constants/AccountPageConstants'

function BillingModalMainRenew ({ subscription = {}, children, postDiv }) {
  const cancelSubscription = () =>
  {
    CashierActions.gotoTeamModal(PAUSE_FEEDBACK)
  }

  return (<BillingModalMainWrapper
    subscription={subscription}
    buttonText="Cancel Subscription"
    onButtonClick={cancelSubscription}
    postDiv={postDiv}
    >
      {children}
  </BillingModalMainWrapper>)
}

export default BillingModalMainRenew;
