import AppDispatcher from 'dispatcher/AppDispatcher'
import QualConstants from 'constants/QualConstants'
import descriptorReducer  from 'reducers/DescriptorReducer'
import ownerMapper from 'mappers/OwnerMapper'
import State from 'stores/State'
var _ = require('underscore');

var EventEmitter = require('events').EventEmitter;

var UserStore = _.extend({}, EventEmitter.prototype, {
  getUserId: function() {
    return State.get().entities.user.id;
  },

  getUser: function(projectID)
  {
    return ownerMapper(State.get(), projectID);
  },

  getStateWithMapper: function(mapper)
  {
    return mapper(State.get());
  },

  // Emit Change event
  emitChange: function() {
    this.emit('change');
  },

  // Add change listener
  addChangeListener: function(callback) {
    this.on('change', callback);
  },

  // Remove change listener
  removeChangeListener: function(callback) {
    this.removeListener('change', callback);
  }
});

// Register callback with AppDispatcher
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType)
  {
    case QualConstants.ACCEPT_TERMS_OF_SERVICE_RESULT:
    case QualConstants.FETCH_USER_RESULT:
      State.set(descriptorReducer(action, State.get()));
    break;
    default:
      return true;
  }

  UserStore.emitChange();
  return true;
});

export default UserStore
